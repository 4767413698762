import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class CareerRollTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="column is-7">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-flex" key={post.id}>
              <Link
                className={`article-list-item is-flex is-flex-direction-column tile is-child box ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
                to={post.fields.slug}
              >
                <header>
                  <div className="block post-meta">
                    <p className="title is-size-4 mb-0">
                      {post.frontmatter.title}
                    </p>
                  </div>
                </header>
                <p className="is-flex-grow-1">
                  {post.frontmatter.description}
                </p>
              </Link>
            </div>
          ))}
      </div>
    )
  }
}

CareerRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function CareerRoll() {
  return (
    <StaticQuery
      query={graphql`
        query CareerRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "career-listing" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 200)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  description
                  location
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <CareerRollTemplate data={data} count={count} />}
    />
  );
}
